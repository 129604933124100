import React, { FC, useState } from 'react';
import Modal from '@bit/together-price.core.components.modal';
import {
  TextField, Typography, makeStyles, Button
} from '@material-ui/core';
import { Trans } from 'react-i18next';
import { fetchUms } from '@bit/together-price.core.api.services';
import { useApp } from '@bit/together-price.core.components.app-context';
import SpinnerAdornment from '../SpinnerAdornment';

const useStyles = makeStyles((theme) => ({
  root: {},
  input: {
    margin: theme.spacing(5, 0),
  },
  button: {
    boxShadow: 'none',
    height: 55,
    textTransform: 'none',
    borderRadius: theme.spacing(2),
  },
}));

export type DeviceEditProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  deviceId: number;
  defaultValue?: string;
  callback?: (name: string) => void;
};
const DeviceEdit: FC<DeviceEditProps> = ({
  open,
  setOpen,
  defaultValue,
  deviceId,
  callback,
}) => {
  const { setError } = useApp();
  const classes = useStyles();
  const [name, setName] = useState(defaultValue);
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Typography variant='h1' color='primary'>
        <Trans i18nKey='Devices.modifyName' />
      </Typography>
      <TextField
        type='text'
        variant='outlined'
        value={name}
        onChange={({ target: { value: val } }) => setName(val)}
        className={classes.input}
      />
      <Button
        className={classes.button}
        variant='contained'
        color='primary'
        disabled={!name || isLoading}
        onClick={() => {
          setIsLoading(true);
          fetchUms('/device/updateDevice', {
            method: 'POST',
            body: JSON.stringify({ deviceId, name }),
          })
            .then(() => {
              callback(name);
              setOpen(false);
            })
            .catch(({ data: { message } }) => {
              setError(message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        fullWidth
      >
        {isLoading ? <SpinnerAdornment /> : <Trans i18nKey='Devices.save' />}
      </Button>
      <Button
        className={classes.button}
        variant='text'
        color='primary'
        disabled={isLoading}
        onClick={() => {
          setOpen(false);
        }}
        fullWidth
      >
        <Trans i18nKey='Devices.cancel' />
      </Button>
    </Modal>
  );
};

export default DeviceEdit;
