import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { useApp } from '@bit/together-price.core.components.app-context';
import Auth from '@/App/Routes/Auth';
import Login from '@/App/Routes/Login';
import Devices from './Devices';
import { AuthContextProvider } from '@/components/AuthContext';
import LoginActivity from './LoginActivity';

const PublicRoutes = () => {
  return (
    <>
      <Switch>
        <Route path='/pin-login'>
          <>
            <Login />
          </>
        </Route>
        <Route path='/pin-auth'>
          <AuthContextProvider>
            <Auth />
          </AuthContextProvider>
        </Route>
        <Route path='/account/devices'>
          <Devices />
        </Route>
        <Route path='/account/login-activity'>
          <LoginActivity />
        </Route>
      </Switch>
    </>
  );
};

export type RoutesProps = {};
const Routes: React.FC<RoutesProps> = () => {
  const { basename } = useApp();
  return (
    <Router basename={basename}>
      <QueryParamProvider ReactRouterRoute={Route}>
        <PublicRoutes />
      </QueryParamProvider>
    </Router>
  );
};

export default Routes;
