import {
  Grid,
  Typography,
  makeStyles,
  IconButtonProps,
} from '@material-ui/core';
import React, { FC } from 'react';
import SmartPhoneIcon from '@bit/together-price.core.icons.smart-phone';
import MonitorIcon from '@bit/together-price.core.icons.monitor';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@bit/together-price.core.components.icon-button';
import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';
import getTimeDiff, { TimeType } from '@/functions/getTimeDiff';

export const useDeviceItemStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
  success: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  deviceWrapper: {
    width: 48,
    height: 48,
    borderRadius: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deviceWrapperBg: {
    // ? INFO(suley): it is not in deviceWrapper because deviceWrapper is in use by Skeleton component too
    backgroundColor: '#F9F7FD',
  },
  device: {
    fill: 'none',
  },
  infoWrapper: {
    padding: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  withLine: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  button: {
    height: 40,
  },
}));

export type DeviceItemProps = {
  platform: string;
  lastLogin?: number;
  isCurrent?: boolean;
  isNotRegistered?: boolean;
  isLast?: boolean;
  ip?: string;
  name?: string;
  status?: string;
  onSettings?: IconButtonProps['onClick'];
  loginDetail?: TimeType;
};
const DeviceItem: FC<DeviceItemProps> = ({
  name,
  platform,
  lastLogin,
  isCurrent,
  isNotRegistered,
  ip,
  isLast,
  status,
  onSettings,
  loginDetail,
}) => {
  const classes = useDeviceItemStyles();
  const { t } = useTranslation();
  const color =
    isNotRegistered || status === 'PENDING_VERIFICATION'
      ? 'warning'
      : 'success';
  const showStatus = isCurrent || status === 'PENDING_VERIFICATION';

  const getText = () => {
    if (isCurrent && status === 'PENDING_VERIFICATION')
      return 'Devices.pendingCurrentDevice';
    if (!isCurrent && status === 'PENDING_VERIFICATION')
      return 'Devices.pendingDevice';
    if (isCurrent && isNotRegistered) return 'Devices.notRegistered';
    if (isCurrent && !isNotRegistered) return 'Devices.currentDevice';
  };

  return (
    <Grid
      item
      container
      direction='row'
      justifyContent='flex-start'
      xs
      className={clsx(classes.root, { [classes.withLine]: !isLast })}
    >
      <Grid
        item
        className={clsx(classes.deviceWrapper, classes.deviceWrapperBg)}
      >
        {platform === 'MOBILE' && <SmartPhoneIcon className={classes.device} />}
        {platform === 'DESKTOP' && <MonitorIcon className={classes.device} />}
      </Grid>
      <Grid item className={classes.infoWrapper}>
        <Typography variant='subtitle1' color='primary'>
          {name}
        </Typography>
        <Typography variant='subtitle2' color='textSecondary'>
          {ip}
        </Typography>
        {showStatus ? (
          <Typography className={classes[color]} variant='subtitle2'>
            <Trans i18nKey={getText()} />
          </Typography>
        ) : (
          <Typography variant='body2' color='textSecondary'>
            {lastLogin
              ? getTimeDiff({ time: lastLogin, translate: t, loginDetail })
              : ''}
          </Typography>
        )}
      </Grid>
      {onSettings && (
        <IconButton className={classes.button} onClick={onSettings}>
          <MoreVertIcon />
        </IconButton>
      )}
    </Grid>
  );
};

export default DeviceItem;
