import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import PinSecurityIcon from '@dev-togetherprice/icons.pin-security';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import Typography from '@material-ui/core/Typography';
import { IconButton, IconButtonProps } from '@material-ui/core';
import { ReactElement } from 'react';
import { mergeClasses } from '@material-ui/core/node_modules/@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  iconWrapper: {
    width: 40,
    height: 40,
    backgroundColor: theme.palette.primary.light,
    borderRadius: theme.spacing(2),
    '& svg': {
      marginLeft: -theme.spacing(1),
      marginTop: -theme.spacing(1),
      width: 32,
      height: 32,
    },
  },
  icon: {
    fill: 'transparent',
  },
  title: {
    flex: 1,
    padding: theme.spacing(0, 3),
    fontSize: 24,
  },
  closeIconContainer: {
    textAlign: 'right',
    alignSelf: 'flex-start',
  },
  closeIconWrapper: {
    position: 'absolute',
    top: 0,
    padding: 0,
    right: 0,
  },
  closeIcon: {},
}));

type ClassKeys =
  | 'root'
  | 'iconWrapper'
  | 'icon'
  | 'title'
  | 'closeIconContainer'
  | 'closeIconWrapper'
  | 'closeIcon';

export type ClosableHeaderProps = {
  icon?: ReactElement;
  title: string;
  onClose?: IconButtonProps['onClick'];
  classes?: Partial<Record<ClassKeys, string>>;
};

const ClosableHeader: React.FC<ClosableHeaderProps> = ({
  icon,
  title,
  onClose,
  classes: newClasses = {},
}) => {
  const baseClasses = useStyles();
  const classes = mergeClasses({
    baseClasses,
    newClasses,
    Component: ClosableHeader,
  });
  return (
    <Grid container className={classes.root}>
      <Grid container direction='row'>
        <Grid item className={classes.iconWrapper}>
          {icon || <PinSecurityIcon className={classes.icon} />}
        </Grid>
        <Typography variant='h1' color='primary' className={classes.title}>
          {title}
        </Typography>
      </Grid>
      {onClose && (
        <IconButton onClick={onClose} className={classes.closeIconWrapper}>
          <CloseOutlinedIcon className={classes.closeIcon} />
        </IconButton>
      )}
    </Grid>
  );
};

export default ClosableHeader;
