import { Fetcher } from '@bit/together-price.core.api.fetch';
import { fetchUms } from '@bit/together-price.core.api.services';
import { AuthData } from '@bit/together-price.core.components.app-context';
import useSWR from 'swr';
import { DeviceInfoProps } from '@/hooks/useDevice';

export type IRegisteredDeviceProps = {
  createdAt: string;
  model: string;
  platform: string;
  platformVersion: string;
  status: string;
  lastLoginVersion?: string;
  lastLogin?: number;
  ip?: string;
  id?: number;
  isCurrent?: boolean;
  name?: string;
};

// ? INFO(suley);
/** This call is always related to useDeviceService call
 * While useDeviceService is a hook so we need to wait until it is getting resolved
 * useSWR accept a function, if this function gives error than it understand that
 * this call has a dependency so it waits until dependency will be available!
 */
export const useRegisteredDevices = (deviceInfo: DeviceInfoProps) =>
  useSWR(
    () => `/device/getRegisteredDevices?loggedUuid=${deviceInfo.uuid}`,
    fetchUms as Fetcher<Array<IRegisteredDeviceProps>>,
    {
      suspense: true,
      dedupingInterval: 30000,
      refreshInterval: 300000,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    }
  );

type AuthDevice = {
  uuid: string;
  code: string;
  lastLoginVersion: string;
  userId: string;
};
export const authDevice = ({
  code,
  lastLoginVersion,
  uuid,
  userId,
}: AuthDevice): Promise<AuthData> =>
  fetchUms(
    '/device/authorize',
    {
      method: 'POST',
      body: JSON.stringify({
        code,
        lastLoginVersion,
        uuid,
        userId,
      }),
    },
    { noToken: true }
  );

export type DeleteUserDeviceOptions = {
  id: number;
};
export const deleteUserDevice = ({
  id,
}: DeleteUserDeviceOptions): Promise<void> =>
  fetchUms(`/device/deleteUserDevice/${id}`, {
    method: 'DELETE',
  });

type RegisteredDevice = {
  deviceInfo: DeviceInfoProps;
};
export const isDeviceRegistered = (deviceInfo): RegisteredDevice =>
  useSWR(
    () => `/device/isDeviceRegistered?uuid=${deviceInfo.uuid}`,
    fetchUms as Fetcher<RegisteredDevice>,
    {
      suspense: true,
      dedupingInterval: 30000,
      refreshInterval: 300000,
    }
  ).data;

export enum PlatformType {
  MOBILE = 'MOBILE',
  DESKTOP = 'DESKTOP',
}

export enum OSType {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  WINDOWS = 'WINDOWS',
  MAC = 'MAC',
  UNIX = 'UNIX',
  UNKNOWN = 'UNKNOWN',
}

type LoginActivity = {
  ip: string;
  lastLogin: number;
  deviceName: string;
  osName: OSType;
  platform: PlatformType;
};

export const useLoginActivities = () =>
  useSWR<LoginActivity[]>(
    '/loginActivityTracking/getLoginActivities',
    fetchUms,
    {
      suspense: true,
      dedupingInterval: 30000,
      refreshInterval: 300000,
      revalidateOnMount: true,
      revalidateOnFocus: false,
    }
  );
