import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import InfoIcon from '@bit/together-price.core.icons.info';
import {
  ListItem,
  ListItemIcon,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { FunctionComponent, Suspense } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LoginsList from '@/components/LoginsList';
import { DevicesListSkeleton } from '@/components/DevicesList';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  listItem: {
    borderWidth: 0.8,
    borderColor: theme.palette.grey.A200,
    borderStyle: 'solid',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(3),
  },
  icon: {
    color: theme.palette.grey.A200,
  },
  itemIcon: {
    minWidth: 40,
  },
}));
export type DevicesProps = {};
const Devices: FunctionComponent<DevicesProps> = () => {
  const navigateToUrl = useNavigateToUrl();
  const history = useHistory();
  const classes = useStyles();

  return (
    <PageWrapper
      activeTab='home'
      navigateToUrl={navigateToUrl}
      classes={{ mobileInner: classes.root }}
      FullTopAppBarProps={{
        mobileProps: {
          title: <Trans i18nKey='LoginActivity.title' />,
          titleLine: true,
          onBack: () => history.goBack(),
        },
      }}
    >
      <ListItem
        className={classes.listItem}
        disableGutters
        alignItems='flex-start'
      >
        <ListItemIcon className={classes.itemIcon}>
          <InfoIcon className={classes.icon} />
        </ListItemIcon>
        <Typography variant='body1' color='textSecondary'>
          <Trans i18nKey='LoginActivity.description' />
        </Typography>
      </ListItem>
      <Suspense fallback={<DevicesListSkeleton />}>
        <LoginsList />
      </Suspense>
    </PageWrapper>
  );
};

export default Devices;
