import React, { createContext, Dispatch, FC, useReducer } from 'react';
import reducers from './reducers';
import states, { StateType } from './states';

const AuthContext = createContext<{
  state?: StateType;
  dispatch?: Dispatch<{ type: string; payload?: any }>;
}>({});

export const AuthContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, {
    ...states,
  });
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
