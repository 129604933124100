import ACTIONS from './actions';
import { StateType } from './states';

const reducers = (state: StateType, action) => {
  switch (action.type) {
    case ACTIONS.INCREMENT_STEP:
      return { ...state, step: state.step + 1 };
    case ACTIONS.SET_STEP:
      return { ...state, step: action.payload };
    case ACTIONS.SET_PHONE:
      return { ...state, phone: action.payload };
    case ACTIONS.SET_PINCODE:
      return { ...state, pinCode: action.payload };
    default:
      return state;
  }
};
export default reducers;
