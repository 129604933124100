import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Modal from '@dev-togetherprice/components.modal';
import AmazingIcon from '@dev-togetherprice/icons.amazing';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@dev-togetherprice/components.button';
import PinNumberInput from '@dev-togetherprice/components.pin-number-input';
import { useApp } from '@dev-togetherprice/components.app-context';
import TimeButton from '@dev-togetherprice/components.time-button';
import AuthContext, { ACTIONS } from '@/components/AuthContext';
import SpinnerAdornment from '@/components/SpinnerAdornment';
import ClosableHeader from '@/components/ClosableHeader';
import { checkCellphoneVerificationCode } from '@/api/create';
import smsReceiver from '@/utils/smsReceiver';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  continueBtn: {
    height: 56,
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: theme.spacing(2),
  },
  modalTitleWrapper: {
    padding: 0,
  },
  modalTitle: {
    paddingRight: 0,
  },
  drawerContinueBtn: {
    height: 56,
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  pinInput: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 250,
    width: '100%',
  },
  resend: {
    marginTop: theme.spacing(10),
    fontSize: 14,
  },
  drawer: {
    padding: 10,
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
  },
  icon: {
    marginLeft: -theme.spacing(1),
    marginTop: -theme.spacing(1),
  },
}));

type PhoneVerificationStepProps = {};

// eslint-disable-next-line max-len
const PhoneVerificationStep: React.FC<PhoneVerificationStepProps> = () => {
  const {
    state: { phone },
    dispatch,
  } = useContext(AuthContext);
  const { setError } = useApp();
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const isDisabled = (securityCode && securityCode.length < 4) || !securityCode || isLoading;

  const goNextStep = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: 4 });
  };

  const requestNewCode = () => {
    dispatch({ type: ACTIONS.SET_STEP, payload: 2 });
  };

  const checkVerificationCode = () => {
    setIsLoading(true);
    checkCellphoneVerificationCode({
      cellphone: phone,
      code: securityCode,
    })
      .then(() => {
        setIsCodeVerified(true);
        smsReceiver().then((res) => {
          if (res) {
            setSecurityCode(res);
          }
        });
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);
        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    smsReceiver().then((res) => {
      if (res) {
        setSecurityCode(res);
      }
    });
  }, []);

  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Step3Description')}
      </Typography>
      <Box className={classes.pinInput}>
        <PinNumberInput
          variant='input'
          inputProps={{
            id: 'single-factor-code-text-field',
            name: 'otc',
            value: securityCode,
            onChange: ({ currentTarget: { value } }) => {
              setSecurityCode(value.toUpperCase());
            },
            autoFocus: true,
            inputMode: 'text',
            pattern: '/[A-Za-z0-9]/g',
            autoComplete: 'one-time-code',
          }}
        />
      </Box>
      <Button
        variant='contained'
        color='primary'
        className={classes.continueBtn}
        onClick={checkVerificationCode}
        disabled={isDisabled}
        fullWidth
      >
        {isLoading ? <SpinnerAdornment /> : t('PinLogin.Continue')}
      </Button>
      <TimeButton
        time={60}
        classes={{ root: classes.resend }}
        onClick={requestNewCode}
      >
        <Trans i18nKey='PinLogin.goBackAndResendCode' />
      </TimeButton>
      <Modal
        open={isCodeVerified}
        onClose={goNextStep}
      >
        <ClosableHeader
          icon={(
            <AmazingIcon
              color='primary'
              fontSize='large'
              className={classes.icon}
            />
          )}
          classes={{
            root: classes.modalTitleWrapper,
            title: classes.modalTitle,
          }}
          title={t('PinLogin.phoneNumberAdded')}
        />
        <Button
          variant='contained'
          color='primary'
          className={classes.drawerContinueBtn}
          onClick={goNextStep}
          fullWidth
        >
          {t('PinLogin.Continue')}
        </Button>
      </Modal>
    </Grid>
  );
};

export default PhoneVerificationStep;
