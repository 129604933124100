import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import AppErrorMessage from '@bit/together-price.core.components.app-error-message';
import App from './App';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: App,
  errorBoundary(err, info, props) {
    console.error(err, info, props);
    return <AppErrorMessage />;
  },
});

export const { bootstrap, mount, update, unmount } = lifecycles;
