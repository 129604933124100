import React, { useState, useEffect } from 'react';
import { App } from '@capacitor/app';
import { Redirect } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import MobileKeyboard from '@dev-togetherprice/components.mobile-keyboard';
import PinNumberInput from '@dev-togetherprice/components.pin-number-input';
import { BiometryType, NativeBiometric } from 'capacitor-native-biometric';
import { Preferences } from '@capacitor/preferences';
import { Capacitor } from '@capacitor/core';
import { Grid } from '@material-ui/core';
import LazyAvatar from '@dev-togetherprice/components.lazy-avatar';
import {
  useNavigateToUrl,
  useOnAuthSuccess,
  useApp,
  AuthData,
} from '@dev-togetherprice/components.app-context';
import ActivateBiometricLoginModal from '../ActivateBiometricLoginModal/ActivateBiometricLoginModal';
import { authDevice } from '@/api/device';
import { getBiometricTypeKey } from '@/hooks/useBiometricType/useBiometricType';
import useDeviceService from '@/hooks/useDevice';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    color: 'black',
    letterSpacing: 0,
    textAlign: 'center',
    fontSize: 18,
    fontWeight: 600,
  },
  descriptionMini: {
    color: 'black',
    letterSpacing: 0,
    textAlign: 'center',
    fontSize: 18,
    marginTop: 30,
  },
  link: {
    color: '#6A3EEA',
    textDecoration: 'underline',
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  continueBtn: {
    width: '100%',
    margin: '30px 0 25px 0',
    textTransform: 'none',
    height: 56,
  },
  drawerContinueBtn: {
    width: '100%',
    margin: '40px 0 32px 0',
    textTransform: 'none',
    height: 56,
  },
  pinInput: {
    margin: '40px auto 90px auto',
    width: '100%',
    maxWidth: 220,
  },
  miniTitle: {
    margin: '24px 0 16px 0',
    textAlign: 'center',
    maxWidth: 335,
    color: '#5F6165',
  },
  deviceGrid: {
    margin: '8px 0 0 0',
    color: theme.palette.primary.dark,
  },
  deviceIcon: {
    backgroundColor: '#F9F7FD',
    borderRadius: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '4px 2px !important',
    marginLeft: '12px',
  },
  keyboard: {
    maxHeight: 300,
  },
  avatar: {
    width: 56,
    height: 56,
    borderRadius: '50%',
    marginBottom: 20,
  },
}));

const PinLogin: React.FC = () => {
  const { setError } = useApp();
  const [securityCode, setSecurityCode] = useState('');
  const [userInfo, setUserInfo] = useState<Record<string, string>>({});
  const [showFaceId, setShowFaceId] = useState(false);
  const [token, setToken] = useState<AuthData | null>(null);
  const [isBiometricModalOpened, setBiometricModalOpen] = useState(false);
  const [pinData, setPinData] = useState('');
  const classes = useStyles();
  const { t } = useTranslation();
  const { deviceInfo } = useDeviceService();
  const navigateToUrl = useNavigateToUrl();
  const onAuthSuccess = useOnAuthSuccess();
  const isDefaultImage = userInfo.image
    === 'https://d1ug1wtffjdh7z.cloudfront.net/avatars/default-avatar.png';
  const fullName = `${userInfo.name || ''} ${userInfo.surname || ''}`;

  const isNativeBiometricAvailable = Capacitor.isPluginAvailable('NativeBiometric')
    && Capacitor.getPlatform() !== 'web';

  const assignUserInfo = async () => {
    const { value } = await Preferences.get({ key: 'lastUserInfo' });
    const lastUserInfo = JSON.parse(value);
    if (!lastUserInfo || !lastUserInfo.id) return navigateToUrl('/login');
    setUserInfo(lastUserInfo);
  };

  const verifyBiometric = async () => {
    const { biometryType } = await NativeBiometric.isAvailable();
    return NativeBiometric.verifyIdentity({
      title: t(
        `BiometricAskActivateTitle.${getBiometricTypeKey(biometryType)}`
      ),
    })
      .then(() => true)
      .catch((err) => {
        console.error(err);
        setError(<Trans i18nKey='PinLogin.Biometrics.authenticationFailed' />);
        return false;
      });
  };

  const getCredentials = async () => {
    if (isNativeBiometricAvailable) {
      const { isAvailable } = await NativeBiometric.isAvailable();
      if (isAvailable) {
        return NativeBiometric.getCredentials({
          server: 'app.togetherprice.com',
        });
      }
    }
    return null;
  };

  const continueWithOnlyPinLogin = async (passingToken: AuthData) => {
    await Preferences.set({
      key: 'AuthStore',
      value: JSON.stringify(passingToken),
    });
    await onAuthSuccess(passingToken);
  };

  const continueWithBiometrics = async ({
    username,
    password,
    token: passingToken,
  }) => {
    if (isNativeBiometricAvailable) {
      const { isAvailable, biometryType } = await NativeBiometric.isAvailable();
      if (isAvailable && biometryType !== BiometryType.NONE) {
        const credentials = await getCredentials().catch((err) => {
          console.error(err);
          return null;
        });
        if (
          !credentials
          || credentials?.username !== username
          || credentials?.password !== password
        ) {
          return setBiometricModalOpen(true);
        }
      }
    }
    await continueWithOnlyPinLogin(passingToken);
  };

  const login = async ({ username, password }) => {
    const app = await App.getInfo();
    authDevice({
      code: password,
      lastLoginVersion: app.version,
      uuid: deviceInfo.uuid,
      userId: username,
    })
      .then(async (data: AuthData) => {
        setToken(data);
        setPinData(password);
        await continueWithBiometrics({ username, password, token: data });
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);
        setSecurityCode('');
        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      });
  };
  const onPinCodeSubmit = async (password: string) => {
    await login({ username: String(userInfo.id), password });
  };

  const onKeyboardTouch = (key: string) => {
    if (key === 'remove') {
      setSecurityCode(securityCode.slice(0, -1));
    } else {
      const value = `${securityCode}${key}`;
      setSecurityCode(value);
      if (value.length >= 4) {
        onPinCodeSubmit(value);
      }
    }
  };

  const hasCorrectCredentials = async () => {
    const credentials = await getCredentials();
    const { isAvailable } = await NativeBiometric.isAvailable();
    return (
      isAvailable
      && credentials
      && credentials?.username
      && credentials?.password
      && credentials?.username === String(userInfo.id)
    );
  };

  const loginWithBiometric = async () => {
    const credentials = await getCredentials().catch(() => null);
    const verified = await verifyBiometric();
    if (verified) {
      await login({
        username: credentials?.username,
        password: credentials?.password,
      });
    }
  };
  const onLoginClear = async () => {
    await Preferences.remove({ key: 'isDeviceRegistered' });
    await Preferences.remove({ key: 'UserStore' });
    await Preferences.remove({ key: 'lastUserInfo' });
    await Preferences.remove({ key: 'AuthStore' });
    navigateToUrl('/login');
  };

  const activateBiometricCredentials = async () => {
    const res = await verifyBiometric();
    if (!res) return;
    await NativeBiometric.setCredentials({
      server: 'app.togetherprice.com',
      username: String(userInfo?.id),
      password: pinData,
    })
      .then(() => continueWithOnlyPinLogin(token))
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);
        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      });
  };

  useEffect(() => {
    assignUserInfo();
  }, []);

  useEffect(() => {
    if (deviceInfo?.uuid) {
      hasCorrectCredentials().then((res) => {
        if (res) loginWithBiometric();
        setShowFaceId(res);
      });
    }
  }, [deviceInfo]);

  if (!Capacitor.isNativePlatform()) return <Redirect to='/' />;
  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Grid container direction='column' alignItems='center'>
        <LazyAvatar
          classes={{ avatar: classes.avatar }}
          src={!isDefaultImage ? userInfo.image : null}
          alt={fullName}
        />
        <Typography variant='h1' className={classes.description}>
          {t('PinLogin.pinLoginTitle', {
            name: fullName,
          })}
        </Typography>
        <Typography variant='subtitle1' className={classes.miniTitle}>
          {t('PinLogin.pinLoginDescription')}
        </Typography>
        <Box className={classes.pinInput}>
          <PinNumberInput
            variant='password'
            disableKeyboard
            inputProps={{
              inputMode: 'none',
              value: securityCode,
              onChange: ({ currentTarget: { value } }) =>
                setSecurityCode(value),
            }}
          />
        </Box>
      </Grid>
      <MobileKeyboard
        classes={{ root: classes.keyboard }}
        faceId={showFaceId}
        faceIdCallback={loginWithBiometric}
        onKeyDown={(value: string) => onKeyboardTouch(value)}
      />
      <Typography variant='h2' className={classes.descriptionMini}>
        {t('PinLogin.enterWithDifferent')}
        {' '}
        <span className={classes.link} onClick={onLoginClear}>
          {t('PinLogin.login')}
        </span>
      </Typography>
      <ActivateBiometricLoginModal
        open={isBiometricModalOpened}
        onClose={() => continueWithOnlyPinLogin(token)}
        onActivate={activateBiometricCredentials}
      />
    </Grid>
  );
};

export default PinLogin;
