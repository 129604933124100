import React, { useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Capacitor } from '@capacitor/core';
import { Redirect } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import Page from '@bit/together-price.core.components.page';
import ClosableHeader from '@/components/ClosableHeader';
import StepOne from '@/components/AuthSteps/StepOne';
import PhoneStep from '@/components/AuthSteps/PhoneStep';
import PhoneVerificationStep from '@/components/AuthSteps/PhoneVerificationStep';
import PinStep from '@/components/AuthSteps/PinStep';
import PinValidateStep from '@/components/AuthSteps/PinValidateStep';
import CodeValidateStep from '@/components/AuthSteps/CodeValidateStep';
import AlreadyRegisteredStep from '@/components/AuthSteps/AlreadyRegisteredStep';
import AuthContext from '@/components/AuthContext';
import EmailNotVerifiedStep from '@/components/AuthSteps/EmailNotVerifiedStep';
import useDeviceService from '@/hooks/useDevice';

export const EMAIL_NOT_VERIFIED_STEP = 'email_not_verified';
export const EMAIL_NOT_VERIFIED_ERROR_MESSAGE = 'USER_EMAIL_NOT_VERIFIED_YET';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(10, 4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    paddingTop: theme.spacing(1.5),
    paddingRight: theme.spacing(4),
  },
}));

type Severity = 'error' | 'warning' | 'success' | 'info';
export interface INotificationProp {
  severity?: Severity;
  message: string;
}

const Auth: React.FC = () => {
  const classes = useStyles();
  const {
    state: { step },
  } = useContext(AuthContext);
  const { t } = useTranslation();
  const navigateToUrl = useNavigateToUrl();

  const { isRegistered } = useDeviceService();

  const onClose = async () => {
    navigateToUrl('/v2');
  };

  const stepRender = {
    1: <StepOne onSkip={onClose} />,
    2: <PhoneStep />,
    3: <PhoneVerificationStep />,
    4: <PinStep />,
    5: <PinValidateStep />,
    6: <CodeValidateStep />,
    7: <AlreadyRegisteredStep />,
    [EMAIL_NOT_VERIFIED_STEP]: <EmailNotVerifiedStep />
  };

  useEffect(() => {
    if (isRegistered) {
      navigateToUrl('/pin-login');
    }
  }, [isRegistered]);

  /**
   * comment this if you want to check the micro-frontend in standalone version
   */
  if (!Capacitor.isNativePlatform()) return <Redirect to='/' />;
  return (
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth='sm' disableGutters>
        <ClosableHeader
          title={t(`PinLogin.Step${step}Title`)}
          onClose={(step < 6 || EMAIL_NOT_VERIFIED_STEP) && onClose}
          classes={{ title: classes.title }}
        />
        {stepRender[step]}
      </Container>
    </Page>
  );
};

export default Auth;
