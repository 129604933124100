import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Container from '@material-ui/core/Container';
import Page from '@bit/together-price.core.components.page';
import PinLogin from '../../../components/PinLogin';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'white',
    padding: theme.spacing(10, 4),
    paddingBottom: theme.spacing(4),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  return (
    <Page className={classes.root}>
      <Container className={classes.container} maxWidth='sm' disableGutters>
        <PinLogin />
      </Container>
    </Page>
  );
};

export default Login;
