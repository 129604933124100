import React, { useState, FC } from 'react';
import Modal from '@dev-togetherprice/components.modal';
import DoneIcon from '@material-ui/icons/Done';
import { Trans } from 'react-i18next';
import globalTheme from '@dev-togetherprice/theme';
import { useApp } from '@dev-togetherprice/components.app-context';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { deleteUserDevice } from '@/api/device';
import SettingsButton, {
  SettingsButtonProps,
} from '@/components/SettingsButton';

export type DeviceSettingsProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  callback: () => void;
  deviceId: number;
  onEditClick?: SettingsButtonProps['onClick'];
};
const DeviceSettings: FC<DeviceSettingsProps> = ({
  open,
  setOpen,
  callback,
  deviceId,
  onEditClick,
}) => {
  const { setError } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <SettingsButton
        disabled={isLoading}
        onClick={onEditClick}
        icon={<DoneIcon fontSize='small' />}
        text={<Trans i18nKey='Devices.modifyName' />}
      />
      <SettingsButton
        disabled={isLoading}
        isLoading={isLoading}
        onClick={async () => {
          setIsLoading(true);
          await deleteUserDevice({ id: deviceId })
            .then(callback)
            .catch(({ data: { message } }) => {
              setError(message);
            })
            .finally(() => {
              setIsLoading(false);
              setOpen(false);
            });
        }}
        icon={<KeyboardArrowRightIcon />}
        text={<Trans i18nKey='Devices.untrustDevice' />}
        iconColor={globalTheme.palette.error.light}
      />
    </Modal>
  );
};

export default DeviceSettings;
