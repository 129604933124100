import React, { FunctionComponent, Suspense } from 'react';
import PageWrapper from '@bit/together-price.core.components.page-wrapper';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import DevicesList, { DevicesListSkeleton } from '@/components/DevicesList';

export type DevicesProps = {};
const Devices: FunctionComponent<DevicesProps> = () => {
  const navigateToUrl = useNavigateToUrl();
  const history = useHistory();

  return (
    <PageWrapper
      activeTab='home'
      navigateToUrl={navigateToUrl}
      FullTopAppBarProps={{
        mobileProps: {
          title: <Trans i18nKey='Devices.title' />,
          titleLine: true,
          onBack: () => history.goBack(),
        },
      }}
    >
      <Suspense fallback={<DevicesListSkeleton />}>
        <DevicesList />
      </Suspense>
    </PageWrapper>
  );
};

export default Devices;
