import { useEffect, useState } from 'react';
import { BiometryType, NativeBiometric } from 'capacitor-native-biometric';

export type BiometricTypeKey =
  | 'NONE'
  | 'TOUCH_ID'
  | 'FACE_ID'
  | 'FINGERPRINT'
  | 'FACE_AUTHENTICATION'
  | 'IRIS_AUTHENTICATION'
  | 'MULTIPLE';

export const getBiometricTypeKey = (type: BiometryType): BiometricTypeKey => {
  switch (type) {
    default:
    case BiometryType.NONE:
      return 'NONE';
    case BiometryType.TOUCH_ID:
      return 'TOUCH_ID';
    case BiometryType.FACE_ID:
      return 'FACE_ID';
    case BiometryType.FINGERPRINT:
      return 'FINGERPRINT';
    case BiometryType.FACE_AUTHENTICATION:
      return 'FACE_AUTHENTICATION';
    case BiometryType.IRIS_AUTHENTICATION:
      return 'IRIS_AUTHENTICATION';
    case BiometryType.MULTIPLE:
      return 'MULTIPLE';
  }
};

export const useBiometricType = (): BiometryType => {
  const [type, setType] = useState(BiometryType.NONE);
  useEffect(() => {
    NativeBiometric.isAvailable()
      .then(({ biometryType }) => setType(biometryType))
      .catch((err) => {
        console.error('Biometric availability error:', err);
        setType(BiometryType.NONE);
      });
  }, []);
  console.log('NativeBiometricType:', type);

  return type;
};
