import {
  CircularProgress,
  fade,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { mergeClasses } from '@material-ui/styles';
import React, { FC, MouseEventHandler, ReactNode } from 'react';
import globalTheme from '@bit/together-price.core.theme';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles<
  Theme,
  { iconColor?: SettingsButtonProps['iconColor'] }
>((theme) => ({
  root: {
    margin: theme.spacing(2, 0),
    minHeight: 55,
    padding: theme.spacing(2, 4),
    border: `1.5px solid ${theme.palette.primary.light}`,
    borderRadius: theme.spacing(2),
    backgroundColor: fade(theme.palette.primary.light, 0.35),
    cursor: 'pointer',
    '-webkit-tap-highlight-color': 'transparent',
  },
  icon: {
    width: 32,
    height: 32,
    backgroundColor: ({ iconColor }) => iconColor,
    color: theme.palette.common.white,
    borderRadius: '50%',
    marginRight: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    flex: 1,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  circularProgress: {
    margin: '0 auto',
  },
  arrow: {},
}));

type ClassKeys = 'root' | 'icon' | 'text' | 'arrow' | 'circularProgress';
export type SettingsButtonProps = {
  classes?: Partial<Record<ClassKeys, string>>;
  iconColor?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  icon: ReactNode;
  text: string | ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
};
const SettingsButton: FC<SettingsButtonProps> = ({
  classes: newClasses = {},
  onClick,
  disabled,
  isLoading,
  iconColor = globalTheme.palette.primary.dark,
  icon,
  text,
}) => {
  const baseClasses = useStyles({ iconColor });
  const classes = mergeClasses({
    newClasses,
    baseClasses,
    Component: SettingsButton,
  });
  return (
    <Grid
      className={classes.root}
      container
      direction='row'
      alignItems='center'
      onClick={!disabled && onClick}
    >
      {isLoading ? (
        <CircularProgress className={classes.circularProgress} size={20} />
      ) : (
        <>
          <Grid item className={classes.icon}>
            {icon}
          </Grid>
          <Typography
            className={classes.text}
            variant='body2'
            color='textSecondary'
          >
            {text}
          </Typography>
          <ArrowForwardIos
            color='primary'
            fontSize='small'
            className={classes.arrow}
          />
        </>
      )}
    </Grid>
  );
};

export default SettingsButton;
