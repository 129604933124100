import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Modal from '@bit/together-price.core.components.modal';
import Button from '@bit/together-price.core.components.button';
import PinNumberInput from '@bit/together-price.core.components.pin-number-input';
import {
  useApp,
  useNavigateToUrl,
} from '@bit/together-price.core.components.app-context';
import AmazingIcon from '@bit/together-price.core.icons.amazing';
import TimeButton from '@bit/together-price.core.components.time-button';
import { resendVerificationCode, verifyDevice } from '@/api/create';
import SpinnerAdornment from '@/components/SpinnerAdornment';
import ClosableHeader from '@/components/ClosableHeader';
import useDeviceService from '@/hooks/useDevice';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  continueBtn: {
    height: 56,
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: theme.spacing(2),
  },
  modalTitleWrapper: {
    padding: 0,
  },
  modalTitle: {
    paddingRight: 0,
  },
  drawerContinueBtn: {
    height: 56,
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: theme.spacing(2),
    marginTop: theme.spacing(6),
  },
  resend: {
    marginTop: theme.spacing(10),
    fontSize: 14,
  },
  pinInput: {
    marginBottom: theme.spacing(10),
    marginTop: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 250,
    width: '100%',
  },
  drawerDescription: {
    margin: theme.spacing(4, 0),
  },
}));
export type CodeValidateStepProps = {};

const CodeValidateStep: React.FC<CodeValidateStepProps> = () => {
  const { setError } = useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [securityCode, setSecurityCode] = useState('');
  const [isDrawerOpened, toggleDrawer] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { deviceInfo, registerUser } = useDeviceService();
  const isDisabled = (securityCode && securityCode.length < 4) || !securityCode || isLoading;
  const navigateToUrl = useNavigateToUrl();

  const requestNewCode = () =>
    resendVerificationCode(deviceInfo.uuid).catch((err) => {
      console.error(err);
      const message = err?.data.message || '';
      setError(
        <Trans
          i18nKey='PinLogin.error'
          values={{ error: JSON.stringify(message) }}
        />
      );
    });

  const onSubmitClick = () => {
    setIsLoading(true);
    verifyDevice({
      uuid: deviceInfo.uuid,
      code: securityCode,
    })
      .then(() => {
        toggleDrawer(true);
        registerUser();
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);
        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const onDashboardClick = () => {
    navigateToUrl('/v2');
  };
  return (
    <Box className={classes.stepContainer}>
      <Typography variant='subtitle2' color='textSecondary'>
        {t('PinLogin.Step3Description')}
      </Typography>
      <Box className={classes.pinInput}>
        <PinNumberInput
          variant='input'
          inputProps={{
            inputMode: 'text',
            value: securityCode,
            onChange: ({ currentTarget: { value } }) =>
              setSecurityCode(value.toUpperCase()),
            autoComplete: 'one-time-code',
            autoFocus: true,
          }}
        />
      </Box>
      <Button
        variant='contained'
        color='primary'
        className={classes.continueBtn}
        onClick={onSubmitClick}
        disabled={isDisabled}
        fullWidth
      >
        {isLoading ? <SpinnerAdornment /> : t('PinLogin.verify')}
      </Button>
      <TimeButton
        time={60}
        classes={{ root: classes.resend }}
        onClick={requestNewCode}
      >
        <Trans i18nKey='PinLogin.resendCode' />
      </TimeButton>
      <Modal
        open={isDrawerOpened}
        onClose={onDashboardClick}
      >
        <ClosableHeader
          icon={<AmazingIcon color='primary' fontSize='large' />}
          title={t('PinLogin.deviceRegistered')}
          classes={{
            root: classes.modalTitleWrapper,
            title: classes.modalTitle,
          }}
        />
        <Typography
          className={classes.drawerDescription}
          variant='subtitle2'
          color='textSecondary'
        >
          {t('PinLogin.deviceRegisteredMessage')}
        </Typography>
        <Button
          variant='contained'
          color='primary'
          className={classes.drawerContinueBtn}
          onClick={onDashboardClick}
          fullWidth
        >
          {t('PinLogin.continueToYourSpace')}
        </Button>
      </Modal>
    </Box>
  );
};

export default CodeValidateStep;
