const smsReceiver = async () => {
  if (!(window as any).OTPCredential) return '';

  let timer;

  try {
    const ac = new AbortController();

    timer = setTimeout(() => {
      ac.abort();
    }, 10 * 1000);

    const content = await navigator.credentials.get({
      //@ts-ignore
      otp: { transport: ['sms'] },
      abort: ac.signal,
    });

    clearTimeout(timer);

    const { code } = content as any;
    if (code) return code;
  } catch (e) {
    console.error(e);
    if (timer) clearTimeout(timer);
    if (e.name !== 'AbortError') {
      throw 'Failed to receive SMS';
    }
    return '';
  }
};

export default smsReceiver;
