import { makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React, { FC, useEffect, useState } from 'react';
import { useApp } from '@bit/together-price.core.components.app-context';

const useStyles = makeStyles(() => ({
  alert: {
    position: 'fixed',
    top: 0,
    width: '95%',
    left: '50%',
    transform: 'translateX(-50%)',
    borderRadius: 0,
    zIndex: 999999,
  },
}));
const ErrorToast: FC = () => {
  const classes = useStyles();
  const { error, setError } = useApp();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (error) {
      setShow(true);
      setTimeout(() => {
        setShow(false);

        setError('');
      }, 5000);
    }
  }, [error, setError]);

  if (!show) return null;
  return (
    <Alert variant='filled' severity='error' className={classes.alert}>
      {error}
    </Alert>
  );
};

export default ErrorToast;
