import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonProps, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@dev-togetherprice/components.button';
import clsx from 'clsx';
import { useUserInfo } from '@dev-togetherprice/api.user';
import { isDeviceRegistered } from '@/api/device';
import AuthContext, { ACTIONS } from '@/components/AuthContext';
import useDeviceService from '@/hooks/useDevice';

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  button: {
    textTransform: 'none',
    borderRadius: theme.spacing(2),
    height: 55,
    boxShadow: 'none',
    marginTop: theme.spacing(2),
  },
  continueBtn: {
    marginTop: theme.spacing(17.5),
  },
}));

export type StepOneProps = {
  onSkip: ButtonProps['onClick'];
};
const StepOne: React.FC<StepOneProps> = ({ onSkip }) => {
  const { dispatch } = useContext(AuthContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const userInfo = useUserInfo();
  const { deviceInfo } = useDeviceService();
  const isDeviceRegisteredBefore = isDeviceRegistered(deviceInfo);

  const checkDeviceUserRegister = async () => {
    if (isDeviceRegisteredBefore) {
      return true;
    }
    return false;
  };

  const updateStep = async () => {
    const userRegisteredBefore = await checkDeviceUserRegister();
    if (userInfo && userRegisteredBefore === false) {
      if (userInfo.cellphone && userInfo.cellphone !== null) {
        dispatch({ type: ACTIONS.SET_PHONE, payload: userInfo.cellphone });
        dispatch({ type: ACTIONS.SET_STEP, payload: 4 });
      } else {
        dispatch({ type: ACTIONS.SET_STEP, payload: 2 });
      }
    } else if (userRegisteredBefore === true) {
      dispatch({ type: ACTIONS.SET_STEP, payload: 7 });
    } else {
      dispatch({ type: ACTIONS.SET_STEP, payload: 2 });
    }
  };

  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Step1Description')}
      </Typography>
      <Button
        variant='contained'
        color='primary'
        className={clsx(classes.button, classes.continueBtn)}
        onClick={updateStep}
        fullWidth
      >
        {t('PinLogin.Continue')}
      </Button>
    </Grid>
  );
};

export default StepOne;
