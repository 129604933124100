import React, { useState, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
  fade, Box, Grid, Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import MobileKeyboard from '@bit/together-price.core.components.mobile-keyboard';
import PinNumberInput from '@bit/together-price.core.components.pin-number-input';
import SmartPhone from '@bit/together-price.core.icons.smart-phone';
import AuthContext, { ACTIONS } from '@/components/AuthContext';
import useDeviceService from '@/hooks/useDevice';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  pinInput: {
    margin: theme.spacing(6, 0),
    maxWidth: 250,
    width: '100%',
    borderColor: 'purple',
    borderWidth: '2px'
  },
  miniTitle: {
    margin: theme.spacing(2, 0),
  },
  deviceGrid: {
    color: theme.palette.primary.dark,
  },
  keyboard: {
    maxHeight: 300,
  },
  deviceIcon: {
    width: 40,
    height: 40,
    backgroundColor: fade(theme.palette.primary.light, 0.35),
    borderRadius: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '12px',
    marginRight: '12px',
    '& svg': {
      fill: 'transparent',
      width: 12,
      height: 20,
    },
  },
}));
export type PinStepProps = {};

const PinStep: React.FC<PinStepProps> = () => {
  const { dispatch } = useContext(AuthContext);
  const [securityCode, setSecurityCode] = useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();
  const { deviceInfo } = useDeviceService();

  const onPinCodeSubmit = (value: string) => {
    dispatch({ type: ACTIONS.SET_PINCODE, payload: value });
    dispatch({ type: ACTIONS.INCREMENT_STEP });
  };

  const onKeyboardTouch = (key: string) => {
    if (key === 'remove') {
      setSecurityCode((prev) => prev.slice(0, -1));
    } else {
      const value = [securityCode, key].join('');
      setSecurityCode(value);
      if (value.length === 4) {
        onPinCodeSubmit(value);
      }
    }
  };

  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Step4Description')}
      </Typography>
      <Typography
        variant='subtitle1'
        color='textPrimary'
        className={classes.miniTitle}
      >
        {t('PinLogin.yourDevice')}
      </Typography>
      <Grid container alignItems='center' className={classes.deviceGrid}>
        <Grid item className={classes.deviceIcon}>
          <SmartPhone />
        </Grid>
        <Typography variant='subtitle2' color='primary'>
          {deviceInfo?.name || deviceInfo?.model}
        </Typography>
      </Grid>
      <Typography
        variant='subtitle1'
        color='textPrimary'
        className={classes.miniTitle}
      >
        {t('PinLogin.choosePin')}
      </Typography>
      <Grid container direction='column' alignItems='center'>
        <Box className={classes.pinInput}>
          <PinNumberInput
            variant='input'
            disableKeyboard
            inputProps={{
              value: securityCode,
              onChange: ({ currentTarget: { value } }) => {
                setSecurityCode(value);
              },
            }}
          />
        </Box>
      </Grid>
      <MobileKeyboard
        classes={{ root: classes.keyboard }}
        faceId={false}
        onKeyDown={(value: string) => onKeyboardTouch(value)}
      />
    </Grid>
  );
};

export default PinStep;
