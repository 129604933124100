/* eslint-disable implicit-arrow-linebreak */
import { fetchUms } from '@dev-togetherprice/api.services';

type CellphoneVerification = {
  cellphone: string;
  whatsappPermission: boolean;
};
export const sendCellphoneVerificationCode = ({
  cellphone,
  whatsappPermission,
}: CellphoneVerification): Promise<void> =>
  fetchUms('/sendCellphoneVerificationCode', {
    method: 'POST',
    body: JSON.stringify({
      cellphone,
      whatsappPermission,
    }),
  });

export const resendEmailVerification = (): Promise<void> =>
  fetchUms('/resendVerificationEmail', {
    method: 'POST'
  });

type VerificationCode = {
  cellphone: string;
  code: string;
};
export const checkCellphoneVerificationCode = ({
  cellphone,
  code,
}: VerificationCode): Promise<void> =>
  fetchUms('/checkCellphoneVerificationCode', {
    method: 'POST',
    body: JSON.stringify({
      cellphone,
      code,
    }),
  });

type RegisterDevice = {
  uuid: string;
  name?: string;
  platform: string;
  platformVersion: string;
  model: string;
  loginCode: string;
};
export const registerDevice = ({
  uuid,
  platform,
  platformVersion,
  model,
  loginCode,
  name,
}: RegisterDevice): Promise<void> =>
  fetchUms('/device/registerDevice', {
    method: 'POST',
    body: JSON.stringify({
      uuid,
      platform,
      platformVersion,
      model,
      loginCode,
      name,
    }),
  });

export const resendVerificationCode = (uuid) =>
  fetchUms(`/device/resendVerificationCode/${uuid}`, {
    method: 'POST',
  });
type VerifyDevice = {
  uuid: string;
  code: string;
};
export const verifyDevice = ({ code, uuid }: VerifyDevice): Promise<void> =>
  fetchUms('/device/verifyDevice', {
    method: 'POST',
    body: JSON.stringify({
      code,
      uuid,
    }),
  });
