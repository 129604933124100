import React, { ReactElement, Suspense, useMemo, useState } from 'react';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import {
  StylesProvider,
  createGenerateClassName,
  ThemeProvider,
} from '@material-ui/core/styles';
import theme from '@dev-togetherprice/theme';
import { I18nextProvider } from 'react-i18next';
import { navigateToUrl as spaNavigateToUrl } from 'single-spa';
import AppContext, {
  AppContextValue,
} from '@dev-togetherprice/components.app-context';
import useBindChangeLocaleEvent from '@dev-togetherprice/hooks.use-bind-change-locale-event';
import useLocale from '@dev-togetherprice/hooks.use-locale';
import { makeStyles } from '@material-ui/core';
import AppLinearProgress from '@dev-togetherprice/components.app-linear-progress';
import TopPixelFix from '@dev-togetherprice/components.top-pixel-fix';
import i18n from '@/i18n';
import ErrorToast from '@/components/ErrorToast';
import Routes from './Routes';

/**
 * Can be used to set up default app props in testing.
 * Example:
 *  to test public micro frontend
 *
 *  ```javascript
 *    localStorage.setItem('__MF_AUTH_Custom_Props', JSON.stringify({
 *      public: true,
 *    }))
 *  ```
 */
const customAppPropsKey = '__MF_AUTH_Custom_Props';
const getCustomAppProps = (): Partial<AppProps> =>
  JSON.parse(localStorage.getItem(customAppPropsKey) || '{}');

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: theme.palette.primary.contrastText,
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.white,
    },
  },
}));
export type AppProps = AppContextValue;
const App: React.FC<AppProps> = ({
  name,
  lng,
  basename = '/',
  navigateToUrl = spaNavigateToUrl,
  onAuthSuccess = () => null,
  ...props
}) => {
  const [error, setError] = useState<string | ReactElement>('');
  const classes = useStyles();
  useBindChangeLocaleEvent();
  useLocale(lng);
  const generateClassName = useMemo(
    () =>
      createGenerateClassName({
        productionPrefix: 'tp-n',
        seed: name,
      }),
    [name]
  );
  return (
    <AppContext.Provider
      value={{
        name,
        lng,
        basename,
        navigateToUrl,
        onAuthSuccess,
        error,
        setError,
        ...props,
        ...getCustomAppProps(),
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ScopedCssBaseline className={classes.root}>
          <StylesProvider generateClassName={generateClassName}>
            <ThemeProvider theme={theme}>
              <ErrorToast />
              <TopPixelFix />
              <Suspense fallback={<AppLinearProgress />}>
                <Routes />
              </Suspense>
            </ThemeProvider>
          </StylesProvider>
        </ScopedCssBaseline>
      </I18nextProvider>
    </AppContext.Provider>
  );
};

export default App;
