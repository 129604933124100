import { useState, useEffect } from 'react';
import { Device, DeviceInfo } from '@capacitor/device';
import SecureStorage from '@bit/together-price.core.utils.storage';

export type DeviceInfoProps = {
  registerUser?: () => void;
  uuid?: string;
} & DeviceInfo;

export interface IDeviceServiceProps {
  deviceInfo: DeviceInfoProps;
  isRegistered: boolean;
  registerUser: () => void;
}

export function useDeviceService(): IDeviceServiceProps {
  const [deviceInfo, setDeviceInfo] = useState<DeviceInfoProps>(null);
  const [isRegistered, setIsRegistered] = useState<boolean>(false);

  const updateDeviceInfo = (uuid, device) => {
    const newDeviceObj = {
      uuid,
      ...device,
    };
    setDeviceInfo(newDeviceObj);
  };

  useEffect(() => {
    Promise.all([
      Device.getInfo(),
      Device.getId(),
      SecureStorage.get('isDeviceRegistered') as Promise<string>,
    ]).then(([device, deviceId, isDeviceRegistered]) => {
      updateDeviceInfo(deviceId.identifier, device);
      const res = JSON.parse(isDeviceRegistered);
      setIsRegistered(res);
    });
  }, []);

  const registerUser = () => {
    SecureStorage.set('isDeviceRegistered', 'true');
  };

  return { deviceInfo, isRegistered, registerUser };
}

export default useDeviceService;
