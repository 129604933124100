import { Button, Dialog, DialogContent } from '@material-ui/core';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { makeStyles, styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { BiometryType } from 'capacitor-native-biometric';
import BiometricIcon from '../icons/BiometricIcon';
import {
  getBiometricTypeKey,
  useBiometricType,
} from '../../hooks/useBiometricType/useBiometricType';

const IconContainer = styled('div')(() => ({
  width: '100%',
  flex: 1,
  boxSizing: 'border-box',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
}));

const useStyle = makeStyles((theme) => ({
  contentGridRoot: {
    height: '100%',
  },
  description: { marginTop: theme.spacing(3), marginBottom: theme.spacing(10) },
  icon: {
    width: '30vmin',
    height: '30vmin',
    fill: '#9C9FA3',
  },
  iconWrapper: {
    background: '#F9F9FC',
    border: '2px dashed #9C9FA3',
    width: 'min(100%, 500px)',
    height: 'min(calc(100vw - 48px), 500px, 100%)',
    borderRadius: '50%',
  },
  button: {
    textTransform: 'none',
    boxShadow: 'none',
    height: 56,
    borderRadius: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const getBiometricTypeTranslationKey = (type: BiometryType) =>
  `ActivateBiometricLoginModal.${getBiometricTypeKey(type)}`;

export type ActivateBiometricLoginPopupProps = {
  open?: boolean;
  onActivate?: () => void;
  onClose?: () => void;
};
const ActivateBiometricLoginModal = (
  props: ActivateBiometricLoginPopupProps
) => {
  const { open = false, onActivate, onClose } = props;
  const classes = useStyle();
  const type = useBiometricType();
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen
      aria-labelledby='activate-biometric-login-title'
      aria-describedby='activate-biometric-login-description'
    >
      <DialogContent>
        <Grid container direction='column' className={classes.contentGridRoot}>
          <IconContainer>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='center'
              className={classes.iconWrapper}
            >
              <BiometricIcon className={classes.icon} />
            </Grid>
          </IconContainer>
          <Typography
            id='activate-biometric-login-title'
            variant='h2'
            color='primary'
            align='center'
          >
            {t('ActivateBiometricLoginModal.title', {
              type: t(getBiometricTypeTranslationKey(type)),
            })}
          </Typography>
          <Typography
            id='activate-biometric-login-description'
            variant='caption'
            align='center'
            color='textSecondary'
            className={classes.description}
          >
            {t('ActivateBiometricLoginModal.description', {
              type: t(getBiometricTypeTranslationKey(type)),
            })}
          </Typography>
          <Button
            onClick={onActivate}
            fullWidth
            color='primary'
            autoFocus
            variant='contained'
            size='large'
            className={classes.button}
          >
            {t('ActivateBiometricLoginModal.yes')}
          </Button>
          <Button
            onClick={onClose}
            fullWidth
            variant='text'
            size='large'
            className={classes.button}
          >
            {t('ActivateBiometricLoginModal.no')}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ActivateBiometricLoginModal;
