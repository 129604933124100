import React from 'react';
import { CircularProgress, withStyles } from '@material-ui/core';

const SpinnerAdornment = withStyles({
  root: {
    color: 'white',
  },
})((props) => <CircularProgress {...props} color='inherit' size={20} />);

export default SpinnerAdornment;
