import { TFunction } from 'i18next';
import moment from 'moment';

const TIMES = [
  'year',
  'month',
  'week',
  'day',
  'hour',
  'minute',
  'second',
] as const;

export type TimeType = typeof TIMES[number];

type GetTimeDiffOptions = {
  time: number;
  translate: TFunction;
  loginDetail?: TimeType;
};
const getTimeDiff = ({
  time,
  translate,
  loginDetail,
}: GetTimeDiffOptions): string => {
  const duration = moment.duration(moment().diff(moment(time)));
  const val = TIMES.map((t, index) => {
    const dr = Math.floor(duration.as(t));
    if (dr !== 0) {
      /**
       * ? INFO(suley): Login detail is the detail level of dates
       * For example; if we set loginDetail as 'week' then we will
       * check if the current t is week or not, if current t is
       * */
      if (loginDetail && index < TIMES.indexOf(loginDetail)) {
        return moment(time).format('DD/MM/yyyy HH:mm');
      }
      return `${dr} ${translate(t)} ${translate('past')}`;
    }
    return '';
  }).filter(Boolean);
  return val?.[0] || 'now';
};

export default getTimeDiff;
