import { Grid, makeStyles, Typography } from '@material-ui/core';
import Modal from '@bit/together-price.core.components.modal';
import React, { FC, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import DoneIcon from '@material-ui/icons/Done';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import { Trans } from 'react-i18next';
import useSWR from 'swr';
import { Preferences } from '@capacitor/preferences';
import SettingsButton from '@/components/SettingsButton';
import { useRegisteredDevices } from '@/api/device';
import DeviceItem from '../DeviceItem/DeviceItem';
import DeviceEdit from '../DeviceEdit';
import DeviceSettings from '../DeviceSettings';
import useDeviceService from '@/hooks/useDevice';

export const useDevicesListStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  deviceRow: {
    padding: theme.spacing(2, 0),
  },
  success: {
    color: theme.palette.success.main,
  },
  deviceWrapper: {
    width: 48,
    height: 48,
    borderRadius: theme.spacing(2),
    backgroundColor: '#F9F7FD',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  device: {
    fill: 'none',
  },
  infoWrapper: {
    padding: theme.spacing(0, 4),
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  withLine: {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
  },
  modifyName: {
    margin: theme.spacing(5, 0),
  },
  button: {
    height: 40,
  },
}));

const fetcher = (api: string) => fetch(api).then((res) => res.json());
export type DeviceListProps = {};
const DevicesList: FC<DeviceListProps> = () => {
  const navigateToUrl = useNavigateToUrl();
  const classes = useDevicesListStyles();
  const [openSettings, setOpenSettings] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [deviceEdit, setDeviceEdit] = useState(false);
  const [isCurrentDevice, setIsCurrentDevice] = useState(false);
  const [deviceId, setDeviceId] = useState(-1);
  const { deviceInfo } = useDeviceService();
  const isNative = Capacitor.isNativePlatform();
  const { data: registeredDevices, mutate } = useRegisteredDevices(deviceInfo);
  const { data: ipAddress } = useSWR(
    'https://api.ipify.org?format=json',
    fetcher
  );

  const updatedRegisteredDevices = registeredDevices?.filter(
    ({ status }) => status !== 'PENDING_VERIFICATION'
  );

  const hasCurrent = !!updatedRegisteredDevices?.find(
    ({ isCurrent }) => isCurrent
  );

  if (updatedRegisteredDevices?.length === 0 && !isNative) {
    return (
      <Grid xs={12} container justifyContent='center' alignItems='center'>
        <Typography variant='subtitle1' color='textSecondary'>
          <Trans i18nKey='Devices.noDevice' />
        </Typography>
      </Grid>
    );
  }
  return (
    <Grid container direction='column' className={classes.root}>
      {!hasCurrent && isNative && (
        <DeviceItem
          name={deviceInfo?.name || deviceInfo?.model}
          platform='MOBILE'
          isCurrent
          isNotRegistered
          ip={ipAddress?.ip}
          isLast={updatedRegisteredDevices?.length === 0}
          onSettings={() => {
            setOpenRegister(true);
          }}
        />
      )}
      {updatedRegisteredDevices?.map(
        (
          {
            name, model, platform, lastLogin, isCurrent, ip, id, status
          },
          index
        ) => (
          <DeviceItem
            key={`${id}-${ip}`}
            name={name || model}
            platform={platform}
            lastLogin={lastLogin}
            isCurrent={isCurrent}
            ip={ip}
            isLast={index === updatedRegisteredDevices?.length - 1}
            status={status}
            onSettings={() => {
              setOpenSettings(true);
              if (isCurrent && status === 'PENDING_VERIFICATION') {
                setOpenRegister(true);
              } else {
                setIsCurrentDevice(isCurrent);
              }
              setDeviceId(id);
            }}
          />
        )
      )}
      <DeviceSettings
        open={openSettings}
        setOpen={setOpenSettings}
        onEditClick={() => setDeviceEdit(true)}
        callback={async () => {
          if (isCurrentDevice) {
            await Preferences.remove({ key: 'isDeviceRegistered' });
          }
          mutate(updatedRegisteredDevices?.filter(({ id }) => id !== deviceId));
        }}
        deviceId={deviceId}
      />
      <Modal open={openRegister} onClose={() => setOpenRegister(false)}>
        <SettingsButton
          onClick={() => navigateToUrl('/pin-auth')}
          icon={<DoneIcon fontSize='small' />}
          text={<Trans i18nKey='Devices.registerDevice' />}
        />
      </Modal>
      <DeviceEdit
        open={deviceEdit}
        setOpen={setDeviceEdit}
        defaultValue={
          updatedRegisteredDevices?.find(({ id }) => id === deviceId)?.name
        }
        deviceId={deviceId}
        callback={(name) => {
          const newUpdatedRegisteredDevices = [...updatedRegisteredDevices];
          newUpdatedRegisteredDevices.find(({ id }) => id === deviceId).name = name;
          mutate(newUpdatedRegisteredDevices);
          setOpenSettings(false);
        }}
      />
    </Grid>
  );
};

export default DevicesList;
