import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { useDeviceItemStyles } from './DeviceItem';

const DeviceItemSkeleton = () => {
  const classes = useDeviceItemStyles();
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      className={classes.root}
    >
      <Skeleton className={classes.deviceWrapper} variant='rect' />
      <Grid item className={classes.infoWrapper}>
        <Skeleton height={28} variant='text' />
        <Skeleton height={22} variant='text' />
        <Skeleton height={22} variant='text' />
      </Grid>
    </Grid>
  );
};

export default DeviceItemSkeleton;
