import { capitalize, Grid, makeStyles } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { OSType, PlatformType, useLoginActivities } from '@/api/device';
import DeviceItem from '../DeviceItem';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
  },
}));
const LoginsList: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data: loginActivities } = useLoginActivities();

  return (
    <Grid className={classes.root} container direction='column'>
      {loginActivities
        .sort((a, b) => b.lastLogin - a.lastLogin)
        .map(({ ip, lastLogin, deviceName, osName, platform }, index) => (
          <DeviceItem
            key={`${ip}-${lastLogin}-${index}`}
            name={
              deviceName ||
              t(
                osName === OSType.UNKNOWN || platform === 'MOBILE'
                  ? 'LoginActivity.deviceName'
                  : 'LoginActivity.desktopName',
                {
                  device:
                    osName === OSType.UNKNOWN
                      ? t('LoginActivity.unknown')
                      : capitalize(osName.toLowerCase()),
                }
              )
            }
            platform={
              osName !== OSType.UNKNOWN &&
              (deviceName ? PlatformType.MOBILE : platform)
            }
            lastLogin={lastLogin}
            isCurrent={false}
            ip={t('LoginActivity.ipAddress', { ip })}
            isLast={index === loginActivities?.length - 1}
            loginDetail='minute'
          />
        ))}
    </Grid>
  );
};

export default LoginsList;
