import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';
import { SvgIconProps } from '@material-ui/core';

const FaceId = (props: SvgIconProps) => (
  <SvgIcon width='118' height='114' viewBox='0 0 118 114' fill='none' {...props}>
    <path d='M109 1.16824e-06C113.971 1.38551e-06 118 4.02944 118 9V32C118 33.6569 116.657 35 115 35C113.343 35 112 33.6569 112 32V9C112 7.34315 110.657 6 109 6L86 6C84.3431 6 83 4.65685 83 3C83 1.34315 84.3431 0 86 0L109 1.16824e-06Z' />
    <path d='M9 1.43051e-06C4.02944 1.43051e-06 0 4.02944 0 9V32C0 33.6569 1.34315 35 3 35C4.65685 35 6 33.6569 6 32V9C6 7.34315 7.34315 6 9 6H32C33.6569 6 35 4.65686 35 3C35 1.34315 33.6569 1.43051e-06 32 1.43051e-06H9Z' />
    <path d='M81.6473 82.345C82.8044 81.159 82.7809 79.2597 81.595 78.1027C80.409 76.9456 78.5097 76.9691 77.3527 78.155C70.0478 85.6425 62.6192 87.0417 56.2994 86.0023C49.7856 84.931 44.2046 81.212 41.1213 78.1287C39.9497 76.9571 38.0503 76.9571 36.8787 78.1287C35.7071 79.3003 35.7071 81.1997 36.8787 82.3713C40.6287 86.1214 47.2977 90.6024 55.3256 91.9227C63.5475 93.275 72.9522 91.2575 81.6473 82.345Z' />
    <path d='M35 35.5C36.6569 35.5 38 36.8431 38 38.5V46.5C38 48.1569 36.6569 49.5 35 49.5C33.3431 49.5 32 48.1569 32 46.5V38.5C32 36.8431 33.3431 35.5 35 35.5Z' />
    <path d='M86 38.5C86 36.8431 84.6569 35.5 83 35.5C81.3431 35.5 80 36.8431 80 38.5V46.5C80 48.1569 81.3431 49.5 83 49.5C84.6569 49.5 86 48.1569 86 46.5V38.5Z' />
    <path d='M118 105C118 109.971 113.971 114 109 114H86C84.3431 114 83 112.657 83 111C83 109.343 84.3431 108 86 108H109C110.657 108 112 106.657 112 105V82C112 80.3431 113.343 79 115 79C116.657 79 118 80.3431 118 82V105Z' />
    <path d='M55 70.5C59.9706 70.5 64 66.4706 64 61.5V42C64 40.3431 62.6569 39 61 39C59.3431 39 58 40.3431 58 42V61.5C58 63.1569 56.6569 64.5 55 64.5H50.25C48.5931 64.5 47.25 65.8431 47.25 67.5C47.25 69.1569 48.5931 70.5 50.25 70.5H55Z' />
    <path d='M0 105C0 109.971 4.02944 114 9 114H32C33.6569 114 35 112.657 35 111C35 109.343 33.6569 108 32 108H9C7.34315 108 6 106.657 6 105L6 82C6 80.3431 4.65686 79 3 79C1.34315 79 1.34005e-06 80.3431 1.26763e-06 82L0 105Z' />
  </SvgIcon>
);

export default FaceId;
