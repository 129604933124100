import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import Button from '@bit/together-price.core.components.button';
import { useNavigateToUrl } from '@bit/together-price.core.components.app-context';
import useDeviceService from '@/hooks/useDevice';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    marginTop: theme.spacing(5),
  },
  continueBtn: {
    margin: '93px 0 25px 0',
    textTransform: 'none',
    height: 56,
    borderRadius: 8,
    boxShadow: 'none',
  },
}));
const AlreadyRegisteredStep: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { registerUser } = useDeviceService();
  const navigateToUrl = useNavigateToUrl();
  const onDashboardClick = async () => {
    registerUser();
    navigateToUrl('/v2');
  };
  return (
    <Box className={classes.stepContainer}>
      <Typography variant='subtitle2' color='textSecondary'>
        {t('PinLogin.deviceRegisteredMessage')}
      </Typography>
      <Button
        variant='contained'
        color='primary'
        className={classes.continueBtn}
        onClick={onDashboardClick}
        fullWidth
      >
        {t('PinLogin.continueToYourSpace')}
      </Button>
    </Box>
  );
};

export default AlreadyRegisteredStep;
