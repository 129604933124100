import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DeviceItemSkeleton } from '../DeviceItem';
import { useDevicesListStyles } from './DevicesList';

const DevicesListSkeleton = () => {
  const classes = useDevicesListStyles();
  // giving an effect to component
  const [count, setCount] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount((prev: number) => {
        if (prev === 4) clearInterval(interval);
        return prev + 1;
      });
    }, 400);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <Grid container direction='column' className={classes.root}>
      {new Array(count).fill(1).map((_, index) => (
        <DeviceItemSkeleton key={`DevicesListSkeleton-${index}`} />
      ))}
    </Grid>
  );
};

export default DevicesListSkeleton;
