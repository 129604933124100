import React, { useContext, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@dev-togetherprice/components.button';
import PhoneNumberInput from '@dev-togetherprice/components.phone-number-input';
import { useApp } from '@dev-togetherprice/components.app-context';
import { sendCellphoneVerificationCode } from '@/api/create';
import AuthContext, { ACTIONS } from '@/components/AuthContext';
import SpinnerAdornment from '@/components/SpinnerAdornment';
import { EMAIL_NOT_VERIFIED_ERROR_MESSAGE, EMAIL_NOT_VERIFIED_STEP } from '@/App/Routes/Auth/Auth';

const useStyles = makeStyles((theme: Theme) => ({
  stepContainer: {
    flex: 1,
  },
  description: {
    margin: theme.spacing(6, 0),
  },
  continueBtn: {
    boxShadow: 'none',
    textTransform: 'none',
    height: 56,
    borderRadius: theme.spacing(2),
  },
  phoneInput: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
}));

type PhoneStepProps = {};

const PhoneStep: React.FC<PhoneStepProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const { setError } = useApp();
  const classes = useStyles();
  const { dispatch } = useContext(AuthContext);
  const { t } = useTranslation();

  const onNumberUpdate = (value: string) => {
    setPhoneNumberValue(value);
  };

  const sendVerificationCode = () => {
    setIsLoading(true);

    sendCellphoneVerificationCode({
      cellphone: phoneNumberValue,
      whatsappPermission: false,
    })
      .then(() => {
        dispatch({ type: ACTIONS.SET_PHONE, payload: phoneNumberValue });
        dispatch({ type: ACTIONS.SET_STEP, payload: 3 });
      })
      .catch((err) => {
        console.error(err);
        const message = err?.data?.message || JSON.stringify(err);

        if (message === EMAIL_NOT_VERIFIED_ERROR_MESSAGE) {
          dispatch({ type: ACTIONS.SET_PHONE, payload: phoneNumberValue });
          dispatch({ type: ACTIONS.SET_STEP, payload: EMAIL_NOT_VERIFIED_STEP });

          setError(
            <Trans
              i18nKey='PinLogin.emailNotVerifiedError'
            />
          );
          return;
        }

        setError(
          <Trans
            i18nKey='PinLogin.error'
            values={{ error: JSON.stringify(message) }}
          />
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const numberRegex = new RegExp('\\s*(?:[\\w\\.\\+\\-]){5,}$');
  return (
    <Grid container direction='column' className={classes.stepContainer}>
      <Typography
        className={classes.description}
        variant='subtitle2'
        color='textSecondary'
      >
        {t('PinLogin.Step2Description')}
      </Typography>
      <Grid container justifyContent='center' className={classes.phoneInput}>
        <PhoneNumberInput onChange={onNumberUpdate} />
      </Grid>
      <Button
        variant='contained'
        color='primary'
        className={classes.continueBtn}
        onClick={sendVerificationCode}
        disabled={!numberRegex.test(phoneNumberValue) || isLoading}
        fullWidth
      >
        {isLoading ? <SpinnerAdornment /> : t('PinLogin.sendCode')}
      </Button>
    </Grid>
  );
};

export default PhoneStep;
