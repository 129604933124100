import { SvgIconProps } from '@material-ui/core';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import React from 'react';
import { BiometryType } from 'capacitor-native-biometric';
import FaceId from './FaceId';
import { useBiometricType } from '../../hooks/useBiometricType/useBiometricType';

const BiometricIcon = (props: SvgIconProps) => {
  const type = useBiometricType();
  switch (type) {
    default:
    case BiometryType.NONE:
      return <></>;
    case BiometryType.TOUCH_ID:
      return <FingerprintIcon {...props} />;
    case BiometryType.FACE_ID:
      return <FaceId {...props} />;
    case BiometryType.FINGERPRINT:
      return <FingerprintIcon {...props} />;
    case BiometryType.FACE_AUTHENTICATION:
      return <FaceId {...props} />;
    case BiometryType.IRIS_AUTHENTICATION:
      return <FaceId {...props} />;
  }
};

export default BiometricIcon;
